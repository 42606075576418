<template>
  <div class="list-table">
    <v-container>
      <v-col cols="12">
        <v-subheader>ผลการประเมิน {{ trip.title }}</v-subheader>
      </v-col>
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="selectedActivity" show-arrows>
            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
            <v-tab
              ripple
              v-for="activity in trip.activities"
              :key="activity.id"
              :href="'#tab-' + activity.id"
            >
              กิจกรรม{{ activity.title }}
            </v-tab>
            <v-tabs-items v-model="selectedActivity">
              <v-tab-item
                v-for="activity in trip.activities"
                :key="activity.id"
                :value="'tab-' + activity.id"
              >
                <v-card text>
                  <v-card-text
                    v-for="form in activity.form.data"
                    :key="form.id"
                  >
                    <v-simple-table light>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <h3>
                              {{ form.title }}
                              <a
                                v-bind:href="
                                  '/api/v1/trip/' + trip.id + '/export'
                                "
                                target="_blank"
                              >
                                <v-icon medium color="orange darken-2"
                                  >mdi-download</v-icon
                                >
                              </a>
                            </h3>
                          </tr>
                          <tr>
                            <th width="30%">ผู้ประเมิน</th>
                            <th
                              class="text-left"
                              v-for="field in form.fields"
                              :key="field.label"
                            >
                              {{ field.label }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="assessment in getAssessment(activity.id)"
                            :key="assessment.id"
                          >
                            <td>{{ assessment.uuid }}</td>
                            <td
                              v-for="field in getAssessmentFields(
                                form.title,
                                assessment.data
                              )"
                              :key="field.label"
                            >
                              {{ getValue(field) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import BoxChart from '@/components/widgets/chart/BoxChart'
// import VWidget from '@/components/VWidget'
// import EChart from '@/components/chart/echart'
import Material from 'vuetify/es5/util/colors'
import {
  StackData,
  SinData,
  monthVisitData,
  campaignData,
  locationData
} from '@/api/chart'
import { getTripReport } from '@/api/trip'

// <e-chart
//                       :path-option="[
//                         ['color', [color.lightBlue.base, color.purple.base]],
//                         ['dataset.source', dataset.monthVisit],
//                         ['series[0].type', 'bar'],
//                         ['series[1].type', 'bar'],
//                         ['series[2].type', 'bar']
//                       ]"
//                       height="350px"
//                       width="100%"
//                     ></e-chart>
// EChart
export default {
  name: 'TripReport',
  components: {},
  data() {
    return {
      loading: true,
      selectedActivity: '',
      trip: {},
      report: {},
      activities: {},
      dialog: false,
      assessmentData: {},
      dialogFormTrip: false,
      qrDialog: false,
      manageActivityDialog: false,
      editMode: false,
      search: '',
      downloadUrl: '',
      formModel: {},
      complex: {},
      actions: [],
      dataset: {
        sinData: SinData,
        monthVisit: monthVisitData,
        campaign: campaignData,
        location: locationData,
        stackData: StackData
      },
      color: Material
      // dataset.monthVisit:[]
    }
  },
  created() {
    // fetch data API
    const tripId = this.$route.params.id
    // const promiseTrip = getTripById(tripId)
    // const promissReport = getReportByTrip(tripId)
    // const promissForm = getFormByTripId(tripId)
    console.log(this.color)
    this.loading = true
    getTripReport(tripId).then((trip) => {
      if (trip) {
        this.trip = trip
      } else {
        console.error(`Missing trip data is ${trip}`)
      }
    })
    // .catch((err) => {
    //   console.error(new Error(err))
    // })

    // Promise.all([promiseTrip, promissReport, promissForm])
    //   .then((resps) => {
    //     // console.log(resps)
    //     if (resps.length == 2) {
    //       this.assessmentData = resps[1]
    //       // this.form = resps[2]
    //       // this.randerBarChart()
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(new Error(error))
    //   })
    //   .finally(() => {
    //     this.loading = false
    //   })
    // console.log(this.dataset.monthVisit)
  },
  methods: {
    getAssessment(activityId) {
      return this.trip.assessments[activityId]
      // .filter((item) => {
      //   return item.activity_id == activityId
      // })
    },
    getAssessmentFields(title, assessmentData) {
      const data = assessmentData.find((item) => {
        return item.title == title
      })
      return data ? data.fields : []
    },
    getValue(field) {
      if (field.fieldType == 'ElSwitch') {
        return field.value ? field.activeText : field.inActiveText
      }
      if (field.fieldType == 'Rating') {
        return field.value ? field.texts[field.value - 1] : '-'
      }

      if (field.fieldType == 'CheckboxText') {
        const defaultValue = []
        const options = field.options.reduce((response, option) => {
          if (option.optionData && option.optionData != '') {
            response.push(`${option.optionValue})${option.optionData}`)
          }
          return response
        }, defaultValue)
        return options.length > 0 ? options.join(',') : '-'
      }

      if (!field.value) {
        return '-'
      }
      return field.value
    },
    sumTypeRadio() {
      // this.trip.
    },
    randerBarChart() {
      //console.log(this.assessmentData[0].data)
      const chartData = this.assessmentData[0].data.map((m) => {
        return {
          month: m.fields[0].fieldType,
          'Unique Visit': Math.floor(Math.random() * 1000) + 200,
          'Page View': Math.floor(Math.random() * 1000) + 250
        }
      })
      console.log(chartData)
      this.dataset.data1 = chartData
    }
  }
}
</script>
